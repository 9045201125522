import { createElement, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

import { TypographyProps } from './types';
import { typography, typographyColors } from './typography-helpers';

export const Typography = forwardRef<HTMLElement, TypographyProps>(
  ({ variant, color, className, children, ...rest }, reference) => {
    const { defaultProps, styles } = typography;

    variant = variant ?? defaultProps.variant;
    color = color ?? defaultProps.color;
    className = className ?? defaultProps.className;

    const selectedVariant = styles.variants[variant];

    const typographyVariant = Object.values(selectedVariant).join(' ');
    const typographyColor = typographyColors[color];

    const classes = twMerge(typographyVariant, typographyColor, className);

    switch (variant) {
      case 'h1': {
        return createElement(
          'h1',
          {
            ...rest,
            ref: reference,
            className: classes,
          },
          children,
        );
      }
      case 'h2': {
        return createElement(
          'h2',
          {
            ...rest,
            ref: reference,
            className: classes,
          },
          children,
        );
      }
      case 'h3': {
        return createElement(
          'h3',
          {
            ...rest,
            ref: reference,
            className: classes,
          },
          children,
        );
      }
      case 'h4': {
        return createElement(
          'h4',
          {
            ...rest,
            ref: reference,
            className: classes,
          },
          children,
        );
      }
      case 'h5': {
        return createElement(
          'h5',
          {
            ...rest,
            ref: reference,
            className: classes,
          },
          children,
        );
      }
      case 'h6': {
        return createElement(
          'h6',
          {
            ...rest,
            ref: reference,
            className: classes,
          },
          children,
        );
      }
      case 'lead': {
        return createElement(
          'div',
          {
            ...rest,
            ref: reference,
            className: classes,
          },
          children,
        );
      }
      case 'paragraph': {
        return createElement(
          'div',
          {
            ...rest,
            ref: reference,
            className: classes,
          },
          children,
        );
      }
      case 'small': {
        return createElement(
          'div',
          {
            ...rest,
            ref: reference,
            className: classes,
          },
          children,
        );
      }
      default: {
        return createElement(
          'p',
          {
            ...rest,
            ref: reference,
            className: classes,
          },
          children,
        );
      }
    }
  },
);
