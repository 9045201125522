import clsxm from 'classnames';
import Image, { ImageProps } from 'next/image';
import * as React from 'react';

type NextImageProps = {
  useSkeleton?: boolean;
  imgClassName?: string;
  blurClassName?: string;
  alt: string;
  styles?: { [key: string]: string };
} & (
  | { width: string | number; height: string | number }
  | { layout?: 'fill'; width?: string | number; height?: string | number }
) &
  ImageProps;

export default function NextImage({
  useSkeleton = false,
  src,
  alt,
  className,
  imgClassName,
  blurClassName,
  styles,
  ...rest
}: NextImageProps) {
  const [status, setStatus] = React.useState(
    useSkeleton ? 'loading' : 'complete',
  );
  const widthIsSet = className?.includes('w-') ?? false;

  return (
    <figure
      style={widthIsSet ? undefined : { width: `w-full` }}
      className={className}
    >
      <Image
        className={clsxm(
          imgClassName,
          status === 'loading' && clsxm({ 'animate-pulse': blurClassName }),
        )}
        style={{ ...styles }}
        src={src}
        alt={alt}
        onLoadingComplete={() => setStatus('complete')}
        {...rest}
      />
    </figure>
  );
}
