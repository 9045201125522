import { Variant } from './types';

export const typographyColors = {
  inherit: 'text-inherit',
  current: 'text-current',
  black: 'text-black',
  white: 'text-white',
  gray: 'text-gray-700',
  'light-blue': 'text-light-blue-500',
  blue: 'text-primary',
  red: 'text-red-500',
};

export const typography = {
  defaultProps: {
    variant: 'paragraph' as Variant,
    color: 'inherit' as keyof typeof typographyColors,
    className: '',
  },
  styles: {
    variants: {
      h1: {
        display: 'block',
        fontSmoothing: 'antialiased',
        letterSpacing: 'tracking-normal',
        fontFamily: 'font-title',
        fontSize: 'text-5xl',
        fontWeight: 'font-semibold',
        lineHeight: 'leading-tight',
      },
      h2: {
        display: 'block',
        fontSmoothing: 'antialiased',
        letterSpacing: 'tracking-normal',
        fontFamily: 'font-title',
        fontSize: 'text-4xl',
        fontWeight: 'font-semibold',
        lineHeight: 'leading-[1.3]',
      },
      h3: {
        display: 'block',
        fontSmoothing: 'antialiased',
        letterSpacing: 'tracking-normal',
        fontFamily: 'font-title',
        fontSize: 'text-3xl',
        fontWeight: 'font-semibold',
        lineHeight: 'leading-snug',
      },
      h4: {
        display: 'block',
        fontSmoothing: 'antialiased',
        letterSpacing: 'tracking-normal',
        fontFamily: 'font-title',
        fontSize: 'text-2xl',
        fontWeight: 'font-semibold',
        lineHeight: 'leading-snug',
      },
      h5: {
        display: 'block',
        fontSmoothing: 'antialiased',
        letterSpacing: 'tracking-normal',
        fontFamily: 'font-title',
        fontSize: 'text-xl',
        fontWeight: 'font-semibold',
        lineHeight: 'leading-snug',
      },
      h6: {
        display: 'block',
        fontSmoothing: 'antialiased',
        letterSpacing: 'tracking-normal',
        fontFamily: 'font-title',
        fontSize: 'text-base',
        fontWeight: 'font-semibold',
        lineHeight: 'leading-relaxed',
      },
      lead: {
        display: 'block',
        fontSmoothing: 'antialiased',
        fontFamily: 'font-sans',
        fontSize: 'text-xl',
        fontWeight: 'font-normal',
        lineHeight: 'leading-relaxed',
      },
      paragraph: {
        display: 'block',
        fontSmoothing: 'antialiased',
        fontFamily: 'font-default',
        fontSize: 'text-base',
        fontWeight: 'font-normal',
        lineHeight: 'leading-relaxed',
      },
      small: {
        display: 'block',
        fontSmoothing: 'antialiased',
        fontFamily: 'font-default',
        fontSize: 'text-sm',
        fontWeight: 'font-light',
        lineHeight: 'leading-normal',
      },
    },
    colors: typographyColors,
  },
};
