import NextImage from 'components/image/image';
import { Typography } from 'components/typography/typography-component';
import Link from 'next/link';

export default function Ventures() {
  return (
    <div className="flex h-full min-h-screen flex-col items-center justify-center bg-[#00061A]">
      <div className="flex flex-col items-center justify-center px-5 md:px-10">
        <NextImage
          src="/assets/en-ventures.svg"
          height={310}
          width={310}
          alt="earlynode ventures"
          className="flex justify-center"
        />
        <Typography className="flex w-full justify-center py-5 text-center text-lg text-white md:w-4/6">
          Together with selected angel investors, we invest in pre-seed B2B SaaS
          startups.
        </Typography>
      </div>

      <footer className="absolute bottom-0 flex justify-center">
        <div className="py-10">
          <Typography className="flex justify-center text-center text-[14px] text-lg text-white">
            We are part of the
            <a href="/" className=" px-1 underline">
              EarlyNode
            </a>
            family.
          </Typography>
          <Typography className="flex justify-center text-center text-[14px] text-lg text-white">
            2021 EarlyNode. All rights reserved.
          </Typography>
          <div className="mt-2 flex justify-evenly">
            <Link href="/privacy">
              <Typography
                variant="paragraph"
                className="font-avenir text-[#AEAEAE] opacity-80"
              >
                Privacy
              </Typography>
            </Link>
            <Link href="/imprint">
              <Typography
                variant="paragraph"
                className="font-avenir text-[#AEAEAE] opacity-80"
              >
                Imprint
              </Typography>
            </Link>
          </div>
        </div>
      </footer>
    </div>
  );
}
